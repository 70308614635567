<template>
    <v-card>
        <v-card-title class="py-1">
            {{ $t('subscriptionlist') }}
            <v-spacer />

            <v-icon
                aria-label="Close"
                size="25"
                @click="basketClosed()"
            >
                mdi-close
            </v-icon>
        </v-card-title>

        <v-card-text>
            <v-container
                fluid
                class="ma-0 pa-0"
            >
                <v-row class="ma-0 pa-0">
                    <v-col
                        v-for="item in subscriptionProducts"
                        :key="item.id"
                        cols="12"
                        md="4"
                    >
                        <product 
                            :product="item" 
                            @productadmountchanged="productChanged"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                :disabled="!anyProductWithPositiveAmount"
                color="primary"
                @click="OpenBasket()"
            >
                {{ $t('common-Continue') }}
            </v-btn>
        </v-card-actions>
        <v-dialog
            v-model="dialog"
            width="600px"
            height="600px"
            persistent
        >
            <basket 
                v-if="dialog"
                :relation-id="relationId"
                @close="basketClosed"
                @closesuccess="$emit('closesuccess')"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import {mapActions, mapMutations, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
export default {
    components: {
        Basket : () => import('@/views/components/Basket.vue'),
        Product : () => import('@/views/components/ProductBase.vue')
    },
    props: {
        relationId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            working: true,
            subscriptionProducts: [],
            dialog: false,

            selectedSubscriptionProducts: []
        }
    },
    computed: {
        anyProductWithPositiveAmount(){
            const product = this.selectedSubscriptionProducts.find(x => x.amount > 0)
            if(product){
                return true
            }

            return false
        },     
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    created() {
        this.FetchSubscriptionProducts({pNumber: this.getSelectedPNumber})
            .then(result => {
                this.subscriptionProducts = result.products
                this.working = false
            })
    },
    methods: {
        productChanged(data){
            let product = this.selectedSubscriptionProducts.find(x => x.id === data.productId)
            if(product){
                product.amount = data.amount
            }else{
                this.selectedSubscriptionProducts.push({
                    id: data.productId,
                    amount: data.amount
                })
            }

            const clonedSubscriptionProducts = cloneDeep(this.selectedSubscriptionProducts)
            this.selectedSubscriptionProducts = clonedSubscriptionProducts
            localStorage.setItem('setSelectedProductIdsAndAmount', clonedSubscriptionProducts)
        },
        OpenBasket(){
            const clonedSubscriptionProducts = cloneDeep(this.selectedSubscriptionProducts)
            this.setSelectedProductIdsAndAmount(clonedSubscriptionProducts)

            this.dialog = true
        },
        basketClosed(){
            this.dialog = false
            this.$emit('close')
        },
        ...mapActions({
            FetchSubscriptionProducts: 'FetchSubscriptionProducts'
        }),
        ...mapMutations({
            setSelectedProductIdsAndAmount : 'setSelectedProductIdsAndAmount'
        })
    }
}
</script>